@use './styles/partials/mixins' as *;
@use './styles/partials/fonts' as *;
@use './styles/partials/variables' as *;

body{
        background-position: 75% center;
        background-image: url('./assets/images/background.webp');
        background-repeat: no-repeat;

        
        @include tablet{
                height: 100vh;
        }


}

.group{

}