@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/variables' as *;


.projects{
    color: $primary-textColor;
    @include mobile__body-small;
    text-align: center;

    @include tablet{
        @include tablet__body-medium;
    }

    @include desktop{
        @include desktop__body-large;
    }


    &__group1{
        background-color: $video-background;
        margin: 0rem 1rem 1rem 1rem;
        padding-top: 1rem;
        border-radius: 20px;
        box-sizing: border-box;
        padding-left: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include tablet {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: 1rem 0;

        }

        @include desktop{
            margin-right: 30%;
        }
 
    }

    &__group2{
        background-color: $video-background;
        margin: 0rem 1rem 1rem 1rem;
        padding-top: 1rem;
        border-radius: 20px;
        box-sizing: border-box;
        padding-left: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include tablet {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: 1rem 0;

        }

        @include desktop{
            margin-left: 30%;
            margin-right: 2rem;
        }
 
    }

    &__video{
        width: 35%;
        order: 1;
        @include tablet{
            order: 0;
            width: 24%;
            margin: 0 1rem;
        }

        @include desktop{
            width: 23%;
        }

    }

    &__text{
        order: 2;
        margin: 1rem 1.5rem 1rem 1.5rem;

        @include tablet{
            order: 0;
        }


    }

}